const apiHost = 'https://preprod2.ccstaffapp.lagardere-tr.com';

export const environment = {
  production: false,
  apiHost: process.env.NG_APP_apiHost ?? apiHost,
  apiURL: process.env.NG_APP_apiURL ?? `${apiHost}/api`,
  debug: true,
  azure: {
    loginPath:
      'https://login.microsoftonline.com/8253e606-4d9f-4c70-a951-c0969af1af07/oauth2/v2.0/authorize',
    clientId: '324de672-fe99-4c15-ab2c-af2e8b9fbbf8',
  },
};
